body {
  background-color: #fafafa;
  margin: auto;
}
.loginButton {
  background-image: linear-gradient( to right, #5e93cf, #303a44);
  width: 160px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align:center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
}